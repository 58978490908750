<template>
  <div class="main">
    <div class="main-title">学科能力测评</div>
    <div
      class="question-wrapper lj-u-sm-10 lj-u-sm-offset-1 lj-u-lg-6 lj-u-lg-offset-3 lj-u-end"
    >
      <div class="question-control"></div>
      <div class="question">
        <div
          class="question-percent-wrapper part"
          ref="questionPercentWrapperPart"
          style="display:block"
        >
          <el-tooltip content="当前部分题目进度">
            <span class="percent-text">{{ percent }}</span>
          </el-tooltip>
        </div>
        <div class="part-title">请选择出正确的答案</div>
        <div class="part-progress lj-progress lj-progress-xs">
          <div class="lj-progress-bar" :style="{ width: percent }"></div>
        </div>
        <div class="content">
          <div
            class="title"
            v-html="`${problemNumber}. ${convertImg(currentProblem.title)}`"
          ></div>
        </div>
        <div class="options">
          <button
            class="lj-btn lj-btn-block option"
            @click="onClickChoice('A')"
            v-html="`A. ${convertImg(currentProblem.a)}`"
          ></button>
          <button
            class="lj-btn lj-btn-block option"
            @click="onClickChoice('B')"
            v-html="`B. ${convertImg(currentProblem.b)}`"
          ></button>
          <button
            class="lj-btn lj-btn-block option"
            @click="onClickChoice('C')"
            v-html="`C. ${convertImg(currentProblem.c)}`"
          ></button>
          <button
            class="lj-btn lj-btn-block option"
            @click="onClickChoice('D')"
            v-html="`D. ${convertImg(currentProblem.d)}`"
          ></button>
        </div>
      </div>
    </div>

    <!-- back btn -->
    <div
      class="button-wrapper"
      style="text-align: center; margin-bottom: 10px; padding: 0 10px;margin-top: 40px;"
    >
      <a
        class="lj-btn lj-btn-third lj-btn-block"
        @click="jumpPage('HomeTestIndex')"
      >
        放弃答题，返回测评导航
      </a>
    </div>
  </div>
</template>

<script>
import { querySubjectQues, postSubjectAns } from './../../../api/subject'
export default {
  data: function () {
    return {
      part: 0,
      currentQuestion: 0,
      type: '',
      length: 0,
      isClicked: 0,
      tooFastLimit: !0,
      selectedResult: [],
      continuousTrueAnswer: [],
      problemNumber: 1
    }
  },
  computed: {
    subject: function () {
      return this.$route.query.subject
    },
    currentProblem: function () {
      return (
        // console.log(
        //   this.currentQuestion,
        //   this.selectedProblems[this.currentQuestion].answer,
        //   this.selectedProblems[this.currentQuestion].difficulty,
        //   this.selectedProblems[this.currentQuestion].knowledgepoint
        // ),
        this.selectedProblems[this.currentQuestion]
      )
    },
    selectedProblems: function () {
      var e = []
      switch (Object.values(this.problems).length) {
        case 2:
          var t = !0,
            r = !1,
            n = void 0
          try {
            for (
              var i, o = Object.values(this.problems)[Symbol.iterator]();
              !(t = (i = o.next()).done);
              t = !0
            ) {
              for (var s = i.value, l = 0; l < 6; l++)
                e.push(
                  s[0.6].splice(Math.floor(Math.random() * s[0.6].length), 1)[0]
                )
              e.push(
                s[0.2].splice(Math.floor(Math.random() * s[0.2].length), 1)[0]
              )
              for (var u = 0; u < 5; u++)
                e.push(
                  s[0.8].splice(Math.floor(Math.random() * s[0.8].length), 1)[0]
                )
            }
          } catch (e) {
            ;(r = !0), (n = e)
          } finally {
            try {
              !t && o.return && o.return()
            } finally {
              if (r) throw n
            }
          }
          break
        case 3:
          var c = !0,
            h = !1,
            a = void 0
          try {
            for (
              var d, f = Object.values(this.problems)[Symbol.iterator]();
              !(c = (d = f.next()).done);
              c = !0
            ) {
              for (var p = d.value, m = 0; m < 4; m++)
                e.push(
                  p[0.6].splice(Math.floor(Math.random() * p[0.6].length), 1)[0]
                )
              e.push(
                p[0.2].splice(Math.floor(Math.random() * p[0.2].length), 1)[0]
              )
              for (var b = 0; b < 3; b++)
                e.push(
                  p[0.8].splice(Math.floor(Math.random() * p[0.8].length), 1)[0]
                )
            }
          } catch (e) {
            ;(h = !0), (a = e)
          } finally {
            try {
              !c && f.return && f.return()
            } finally {
              if (h) throw a
            }
          }
          break
        case 4:
          var y = !0,
            g = !1,
            w = void 0
          try {
            for (
              var v, P = Object.values(this.problems)[Symbol.iterator]();
              !(y = (v = P.next()).done);
              y = !0
            ) {
              for (var M = v.value, k = 0; k < 3; k++)
                e.push(
                  M[0.6].splice(Math.floor(Math.random() * M[0.6].length), 1)[0]
                )
              e.push(
                M[0.2].splice(Math.floor(Math.random() * M[0.2].length), 1)[0]
              )
              for (var j = 0; j < 2; j++)
                e.push(
                  M[0.8].splice(Math.floor(Math.random() * M[0.8].length), 1)[0]
                )
            }
          } catch (e) {
            ;(g = !0), (w = e)
          } finally {
            try {
              !y && P.return && P.return()
            } finally {
              if (g) throw w
            }
          }
          break
        case 5:
          var T = !0,
            Q = !1,
            A = void 0
          try {
            for (
              var $, B = Object.values(this.problems)[Symbol.iterator]();
              !(T = ($ = B.next()).done);
              T = !0
            ) {
              var O = $.value
              e.push(
                O[0.6].splice(Math.floor(Math.random() * O[0.6].length), 1)[0]
              ),
                e.push(
                  O[0.2].splice(Math.floor(Math.random() * O[0.2].length), 1)[0]
                )
              for (var N = 0; N < 3; N++)
                e.push(
                  O[0.8].splice(Math.floor(Math.random() * O[0.8].length), 1)[0]
                )
            }
          } catch (e) {
            ;(Q = !0), (A = e)
          } finally {
            try {
              !T && B.return && B.return()
            } finally {
              if (Q) throw A
            }
          }
          break
        default:
          console.error('错误的知识点数量！')
      }
      return e
    },
    /**
     * 直接是一下子获得许多的题目
     */
    problems: function () {
      // reduce()方法，第一个e是要返回的对象，t是当前元素
      // [].map()和foreach一样的
      return Object.entries(
        this.$store.state.Think.problems[this.subject].reduce(function (e, t) {
          // this.$store.state.Think.curproblems.reduce(function (e, t) {
          return (
            e[t.knowledgepoint] || (e[t.knowledgepoint] = []),
            e[t.knowledgepoint].push(t),
            e
          )
        }, {})
      )
        .map(function (e) {
          return [
            e[0],
            e[1].reduce(function (e, t) {
              return (
                e[t.difficulty] || (e[t.difficulty] = []),
                e[t.difficulty].push(
                  Object.assign(t, {
                    id: Number(t.id),
                    knowledgepoint: Number(t.knowledgepoint),
                    difficulty: Number(t.difficulty),
                    selected: !1
                  })
                ),
                e
              )
            }, {})
          ]
        })
        .reduce(function (e, t) {
          return (e[t[0]] = t[1]), e
        }, {})
    },

    percent: function () {
      return (
        (this.length
          ? Math.round((this.currentQuestion / this.length) * 100)
          : 0) + '%'
      )
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  methods: {
    convertImg: function (e) {
      let baseUrl = this.$store.state.Url.baseUrl
      return e.replace(
        /\[img path=\.\/Data\/(.+?)\]/g,
        '<img src="' + baseUrl + 'subject_ability_problem_images/$1">'
      )
    },

    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({path: dest})
    },

    /**
     * 选择了某个具体的choice
     */
    onClickChoice: function (e) {
      var t = this,
        r = //这里的代码真的是相当冗余
          (1 < arguments.length && void 0 !== arguments[1]
            ? arguments[1]
            : this.type,
          e)
      if (this.tooFastLimit)
        this.$message.error('您的答题速度过快，请认真读题后再作答')
      else if (!this.isClicked)
        if (((this.isClicked = !0), this.currentQuestion < this.length - 1)) {
          var n = r === this.currentProblem.answer,
            i = function (e) {
              switch (e) {
                case 0.2:
                  return 1
                case 0.6:
                  return 3
                case 0.8:
                  return 4
              }
            }
          if (
            (this.selectedResult.push([
              n,
              i(this.currentProblem.difficulty),
              this.currentProblem.knowledgepoint,
              this.currentProblem.difficulty,
              this.currentProblem.id
            ]),
            n &&
            this.currentProblem.knowledgepoint ===
              this.continuousTrueAnswer[0] &&
            this.currentProblem.difficulty < 0.8
              ? (this.continuousTrueAnswer = [
                  this.currentProblem.knowledgepoint,
                  this.continuousTrueAnswer[1] + 1
                ])
              : (this.continuousTrueAnswer = [
                  this.currentProblem.knowledgepoint,
                  0
                ]),
            3 === this.continuousTrueAnswer[1])
          ) {
            // 跳题
            for (var o = this.currentQuestion; o < this.length - 1; o++) {
              var s = this.selectedProblems[o]
              s.selected ||
                s.knowledgepoint !== this.continuousTrueAnswer[0] ||
                0.8 !== s.difficulty ||
                ((s.selected = !0),
                this.selectedResult.push([
                  !0,
                  i(s.difficulty),
                  s.knowledgepoint,
                  s.difficulty,
                  s.id
                ]))
            }
            this.continuousTrueAnswer = [this.currentProblem.knowledgepoint, 0]
          }
          for (
            this.currentProblem.selected = !0;
            this.currentQuestion++, this.currentProblem.selected;

          );
          this.problemNumber++,
            (this.tooFastLimit = !0),
            setTimeout(function () {
              t.tooFastLimit = !1
            }, 1e3),
            (this.isClicked = !1)
        } else {
          var l = r === this.currentProblem.answer
          this.selectedResult.push([
            l,
            (function (e) {
              switch (e) {
                case 0.2:
                  return 1
                case 0.6:
                  return 3
                case 0.8:
                  return 4
              }
            })(this.currentProblem.difficulty),
            this.currentProblem.knowledgepoint,
            this.currentProblem.difficulty,
            this.currentProblem.id
          ])
          //这里就是操作哪些数据的关键
          var u = this.selectedResult.reduce(function (e, t) {
              return e + t[1]
            }, 0),
            c = this.selectedResult.reduce(function (e, t) {
              return t[0] ? e + t[1] : e
            }, 0),
            h = Math.round((c / u) * 100),
            a = this.selectedResult
              .map(function (e) {
                return [Number(e[0]), e[2], e[3]]
              })
              .map(function (e) {
                return e[0] + ',' + e[1] + ',' + e[2]
              })
              .join('|'),
            details = this.selectedResult.map(function (e) {
              return {
                id: e[4],
                correct: e[0]
              }
            })
          // 发送每道题的得分和总分给服务端
          postSubjectAns({
            details: details,
            score: h,
            subject: this.subject
          });
          this.$router.push({ path: '/HomeTestIndex' });
          console.log('score is ', h);
          console.log('this.selectedResult is ', this.selectedResult);
          console.log('ans list is', a);
          this.$message.success('您已完成所有题目');
        }
    }
  },
  mounted: function () {
    var t = this
    setTimeout(function () {
      t.tooFastLimit = !1
    }, 1e3)
    this.currentQuestion = 0
    this.length = this.selectedProblems.length
    this.continuousTrueAnswer = [this.currentProblem.knowledgepoint, 0]
  }
}
</script>

<style lang="less">
@import url('./../../../Public/resources/css/templates/home/test/problem.min.css');
@import url('./../../../Public/resources/css//components/progress.min.css');
@import url('./../../../Public/resources/css//components/card.min.css');
@import url('./../../../Public/resources/css//components/grid.min.css');

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
